const mode = window.localStorage.getItem('mode')
const oldVersion = window.localStorage.getItem('oldVersion')
const leftVersion = window.localStorage.getItem('leftVersion')
const background = window.localStorage.getItem('background')
const radio = window.localStorage.getItem('radio')

export const state = {
  mode: mode ? JSON.parse(mode) : true,
  oldVersion: oldVersion ? JSON.parse(oldVersion) : false,
  leftVersion: leftVersion ? JSON.parse(leftVersion) : true,
  links: [
    {
      id: 3,
      name: 'message.operating',
      link: 'current'
    },
    {
      id: 0,
      name: 'message.vacancy',
      link: 'vacancy'
    },
    {
      id: 2,
      name: 'message.o_salary',
      link: 'transaction'
    },
  ],
  radio: radio ? JSON.parse(radio) : 1,
  is_expanded: false,
  background: background ? JSON.parse(background) : {
    id: 55,
    check: true,
    url: '/img/background/22.png',
    name: 'White',
    mode: false
  },

  lock: {
    password: 0, status: false
  },
  dateToday: '',
  getLockTimer: {
    isIdle: true,
    isTime: 0,
    disable: true
  }
}
