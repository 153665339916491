import {i18n} from '@/utils/i18n';  
  export const columns = {
    id: {
      show: true,
      title: i18n.t('message.n'),
      sortable: true,
      column: 'id'
    },
    name: {
      show: true,
      title: i18n.t('message.nameFull'),
      sortable: true,
      column: 'name'
    },
    last_name: {
      show: true,
      title: i18n.t('message.last_name'),
      sortable: true,
      column: 'last_name'
    },
    social_id: {
      show: true,
      title: i18n.t('message.social'),
      sortable: true,
      column: 'social'
    },
    middle_name: {
      show: false,
      title: i18n.t('message.middle_name'),
      sortable: true,
      column: 'middle_name'
    },
    date_of_birth: {
      show: false,
      title: i18n.t('message.date_of_birth'),
      sortable: true,
      column: 'date_of_birth'
    },
    vacancy_ids : {
      show: false,
      title: i18n.t('message.vacancy'),
      sortable: true,
      column: 'vacancy_ids'
  },
    gender_id: {
      show: true,
      title: i18n.t('message.gender'),
      sortable: true,
      column: 'gender_id'
    },
    nation_id: {
        show: true,
        title: i18n.t('message.nation'),
        sortable: true,
        column: 'nation_id'
      },
    phone_number: {
      show: true,
      title: i18n.t('message.phone_number_y'),
      sortable: true,
      column: 'phone_number'
    },
    email: {
      show: false,
      title: i18n.t('message.email'),
      sortable: true,
      column: 'email'
    },
    status_id:{
      show: true,
      title: i18n.t('message.status'),
      sortable: true,
      column: 'status_id'  
    },
    vacancy_ids: {
      show: true,
      title: i18n.t('message.vacancy'),
      sortable: true,
      column: 'vacancy_ids'
    },
    min_wage:{
        show: true,
        title: i18n.t('message.min_wage_y'),
        sortable: true,
        column: 'min_wage'  
    },
    max_wage:{
        show: true,
        title: i18n.t('message.max_wage_y'),
        sortable: true,
        column: 'max_wage'  
    },
    created_at: {
      show: true,
      title: i18n.t('message.created_at'),
      sortable: true,
      column: 'created_at'
    },
    updated_at: {
      show: false,
      title: i18n.t('message.updated_at'),
      sortable: true,
      column: 'updated_at'
    },  
    settings: {
      show: true,
      title: i18n.t('message.settings'),
      sortable: false,
      column: 'settings'
    }   
  
  };
  