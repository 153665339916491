export const state = {
    statistics: [],
    newcomestaffs: [],
    completestaffprofile: [],
    staffagestatistic: [],
    staffupdate: [],
    genderstatistic: [],
    todaybirthdays: [],
    expectedbirthdays: [],
    pastbirthdays: [],
    staffbyeducation: [],
    staffbyreason: [],
    staffworktype: [],
    workexperience: [],
    completeprofilethefiliale: [],
    employessbyregion: [],
    stafffromcandidate: [],
    candidate: [],
    releaseandreception: [],
    source: [],
    wages: [],
    averagesalarybybranches: [],
    staffattendences: [],
    faceidregistration: [],
    attendstaffweek: [],
    visitors: [],
    topmanagers:[],
    stafftelegraminformation: [],
    employeekpp:[],
    transportkpp:[],
    reasonbyoperation:[],
    tmsstatistic:[],
    tmslist:[],
    toptoiletattendants:[],
    topstaffwithtotalwork:[],
    hasSalayStaffs:[],
    requestsForToday:[],
    staffbymood: [],
    situationfortoday: []
};
