import { index, oneStaff, updateOlderAttendance,updateOneStaffOlderAttendance, getOneStaffOneDayAttendance, getInfo, getWorkInfo, newIndex } from "@/api/AttendanceReport/AttendanceReport";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data.staffs);
                  
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res.data.result.data.staffs);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },   
    newIndex({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
          newIndex(params)
              .then(res => {
                  commit("SET_LIST", res.data.result.data.departments);
                  commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                  resolve(res.data.result.data.departments);
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
    },  
    getInfo({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        getInfo(params)
              .then(res => {
                  commit("SET_INFO", res.data.result.data.staffs);
                  resolve(res.data.result.data.staffs);


                  // commit("REFRESH");
                  
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
    },   
    getWorkInfo({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        getWorkInfo(params)
              .then(res => {
                  commit("SET_WORK_INFO", res.data.result.data.staffs);
                  resolve(res.data.result.data.staffs);
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
    },  

    oneStaff({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        oneStaff(params)
              .then(res => {
                  commit("SET_STAFF", res.data.result.data.staffs);
                  resolve(res.data.result.data.staffs);


                  // commit("REFRESH");
                  
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
    },   

    getOneStaffOneDayAttendance({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        getOneStaffOneDayAttendance(params)
              .then(res => {
                  commit("SET_ATTENDANCE_REPORT", res.data.result.data.attendances);
                  resolve(res.data.result.data.attendances);
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
    }, 
  updateOlderAttendance({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      updateOlderAttendance(params)
            .then(res => {
                // commit("SET_STAFF", res.data.result.data.staffs);
                  // commit("REFRESH");
                  resolve(res.data.result);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },  
  updateOneStaffOlderAttendance({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      updateOneStaffOlderAttendance(params)
            .then(res => {
                // commit("SET_STAFF", res.data.result.data.staffs);
                  // commit("REFRESH");
                  resolve(res.data.result);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  }, 

   
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve(resolve)
        })
    },
};
