var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dropdown',{attrs:{"szie":"mini"},on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link more_icons"},[_c('i',{staticClass:"el-icon-arrow-down el-icon-more-outline"})]),_c('el-dropdown-menu',{class:_vm.mode ? 'performance__day' : 'performance__night',attrs:{"slot":"dropdown","size":"mini"},slot:"dropdown"},[(
        _vm.permissions.some(per => per.slug == _vm.permissionShow) &&
        _vm.actions.some(action => action == 'edit')
          ? true
          : false
      )?_c('el-dropdown-item',{attrs:{"command":"edit","icon":"el-icon-edit el-icon--left"}},[_vm._v(" "+_vm._s(_vm.$t("message.edit"))+" ")]):_vm._e(),(
        _vm.permissions.some(per => per.slug == _vm.permissionView) &&
        _vm.actions.some(action => action == 'view')
          ? true
          : false
      )?_c('el-dropdown-item',{attrs:{"command":"view","icon":"el-icon-view el-icon--left"}},[_vm._v(" "+_vm._s(_vm.$t("message.view"))+" ")]):_vm._e(),(
        _vm.permissions.some(per => per.slug == _vm.permissionDestroy) &&
        _vm.actions.some(action => action == 'delete')
          ? true
          : false
      )?_c('el-dropdown-item',{attrs:{"command":"delete","icon":"el-icon-delete el-icon--left"}},[_vm._v(" "+_vm._s(_vm.$t("message.delete"))+" ")]):_vm._e(),(
        _vm.permissions.some(per => per.slug == _vm.permissionAssignRole) &&
        _vm.actions.some(action => action == 'assign_role')
          ? true
          : false
      )?_c('el-dropdown-item',{attrs:{"command":"assignRole","icon":"el-icon-edit el-icon--left"}},[_vm._v(" "+_vm._s(_vm.$t("message.assign_role"))+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }