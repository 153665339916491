import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import store from "@/store/index";
import { model } from "./properties/model";
import { i18n } from "@/utils/i18n";

export const mutations = {
  SET_LIST: (state, departments) => {
    state.list = departments;
  },

  SET_ALL_LIST: (state, departments) => {
    state.allList = departments;
  },
  SET_INVENTORY: (state, departments) => (state.inventory = departments),
  SET_SORT: (state, sort) => (state.sort = sort),
  SET_FILTER: (state, filter) => (state.filter = filter),
  SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
  UPDATE_PAGINATION: (state, pagination) => {
    state.pagination[pagination.key] = pagination.value;
  },
  UPDATE_COLUMN: (state, obj) => {
    state.columns[obj.key].show = obj.value;
  },
  UPDATE_SORT: (state, sort) => {
    state.sort[sort.column] = sort.order;
  },
  SET_MODEL: (state, department) => {
    state.model = department;
  },
  EMPTY_MODEL: state => {
    state.model = JSON.parse(JSON.stringify(model));
  },
  REFRESH: state => {
    state.filter = JSON.parse(JSON.stringify(filter));
    state.sort = JSON.parse(JSON.stringify(sort));
    state.pagination = JSON.parse(JSON.stringify(pagination));
  }
};
