<template>
  <div>
    <el-input
      :value="value"
      @input="dispatch"
      :type="type"
      :placeholder="placeholder"
      :prefix-icon="icon"
      :size="size"
      :class="className"
      clearable
      :key="maskFormat"
      v-mask="getMaskFormat"
      :disabled="disabled"
    >
    </el-input>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
export default {
  name: "user_status",
  props: {
    max: {
      default: 100,
    },
    size: {
      default: "small",
    },
    placeholder: {
      default: "Поиск",
    },
    type: {
      default: "text",
    },
    className: {
      default: "w-100",
    },
    icon: {
      default: null,
    },
    inputValue: {
      default: "",
    },
    maskFormat: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  computed: {
    getMaskFormat: function () {
      if (this.maskFormat == "tel") {
        return "\\9\\98999999999";
      }
      if (this.maskFormat == "passport") {
        return "AA[9]{7}";
      }
       if (this.maskFormat == "pas_uz") {
        return "AA[9]{7}";
      }
      if (this.maskFormat == "pas_ind") {
        return "A[9]{7}";
      }
       if (this.maskFormat == "pas_ru") {
        return "99 9999999";
      }
       if (this.maskFormat == "pas_tr") {
        return "A[9]{8}";
      }
      if (this.maskFormat == "pas_kg") {
        return "AA[9]{7}";
      }
      if (this.maskFormat == "pas_kz") {
        return "A[9]{7}";
      }
      if (this.maskFormat == "pinfl") {
        return "9 999999 999 999 9";
      }
      if (this.maskFormat == "inn") {
        return "999999999";
      }
      if (this.maskFormat == "card") {
        return "9999-9999-9999-9999";
      }
      if (this.maskFormat == "card_expiry_date") {
        return "99/99";
      }
      return this.maskFormat;
    },
  },
  watch: {
    inputValue: {
      handler: function () {
        this.value = this.inputValue;
      },
      immediate: true,
    },
    maskFormat: {
      handler: function () {},
      immediate: true,
      deep: true,
    },
  },
  methods: {
    dispatch(e) {
      this.$emit("input", e);
      this.value = e;
    },
  },
};
</script>
