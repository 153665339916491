import request from '@/utils/request'


export function explanatory_letters(params) {
    return request({
        url: '/staffs/explanatory_letters',
        method: 'get',
        params: params
    })
}
