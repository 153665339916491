<template>
  <div>
    <div class="my___pagination">
    <el-pagination
      :page-size="pagination.per_page"
      :total="pagination.total"
      :current-page="pagination.page"     
      :page-sizes="page_sizes"      
      layout="sizes, prev, pager, next, ->, total, slot"
      @size-change="sizeChange"
      @current-change="currentChange"
      @prev-click="currentChange"
      @next-click="currentChange"
    >
    </el-pagination>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from "vuex";
    export default {
        props: {
            pagination: { type: Object }
        },
        data() {
            return {
            }
        },
        computed: {
          ...mapGetters({
                mode: "MODE"
              }),
            page_sizes: () => {
              return [1,5,10,20,30,40,50,60,70,80,90,100,200,300,400,500,600,700,800,900,1000]
            }
        },
        methods: {
            sizeChange(newPageSize){
                this.$emit('c-change',{key:'per_page',value: newPageSize});                
                this.pagination.per_page = newPageSize;
                this.currentChange(1)
            },
            currentChange(page){
                this.$emit('c-change',{key:'page',value: page});
            }
        },
    }
</script>
<style lang="scss">
.pagination__night{
  .el-pagination__total{
    color: #fff !important;

  }
  .el-input__inner{
    color: #fff !important;
  }
  .my___pagination .el-pager li{
    color: rgb(181, 181, 181) ;
    &.active{
      color: #fff !important;

    }
  }
}
</style>
