import request from "@/utils/request";

export function index(params) {
  return request({
    url: "settings/departments",
    method: "get",
    params: params
  });
}

export function allList(params) {
  return request({
    url: "settings/department/get-all",
    method: "get"
  });
}
export function updateOrder(data) {
  return request({
    url: "settings/department/update-order-all",
    method: "post",
    data
  });
}
export function inventory(params) {
  return request({
    url: "settings/department/inventory",
    method: "get",
    params
  });
}

export function show(id) {
  return request({
    url: `settings/departments/${id}`,
    method: "get"
  });
}

export function store(data) {
  return request({
    url: "settings/departments",
    method: "post",
    data
  });
}

export function update(data) {
  return request({
    url: `settings/departments/${data.id}`,
    method: "put",
    data
  });
}

export function destroy(id) {
  return request({
    url: `settings/departments/${id}`,
    method: "delete"
  });
}
