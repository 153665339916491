<template>
  <div>
     <el-select
      @change="optionChange"
     :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.status')"
      filterable
      clearable
      :size="size"
      class="d-block"
      :class="mode ? 'input__day' : 'input__night'"
    >
      <el-option
        v-for="(status, index) in statuses"
        :key="'status-' + index"
        :label="status.name"
        :value="status.id"
      >
    </el-option>
  </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "status",
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    table_name: {
      default: null,
      type: String,
      required: true,
    },
    id: {
      default: null,
    }
    
  },
  mounted() {
    if (this.statuses){
        this.fetchStatuses();
    }
  },
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    id: {
      handler: function () {
        this.selected = this.id;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      statuses: "statusStaff/inventory",
      mode:'MODE'
      
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "statusStaff/inventory",
    }),
  dispatch(e) {
      this.selected = e;
      this.$emit("input", e);
    },
   
    fetchStatuses() {
      const query = { table_name: this.table_name };
      this.updateInventory(query)
        .then((res) => {                       
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
     optionChange(index){
      this.selected = index;
       let ele =null;
      this.statuses.forEach(element => {
        if ((element.id == index) && (element.code == -1)) {
              this.$emit("parent-function", index)
            }
        });
      
     
      

    },
  },
};
</script>
