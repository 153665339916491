import { vacation, reasons, show, oneStaff, getStaffContact, getStaffRequirement, acquaintedRequirement} from "@/api/staff/openStaffProfile";

export const actions = {       
    show({ commit }, id) {
      return new Promise((resolve, reject) => {
          show(id)
              .then(res => {
                  commit('SET_MODEL', res.data.result.data.staff);                    
                  resolve(res);
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
    },
    oneStaff({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        oneStaff(params)
              .then(res => {
                  commit("SET_STAFF", res.data.result.data.staffs);
                  resolve(res.data.result.data.staffs);


                  // commit("REFRESH");
                  
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
    }, 
    acquaintedRequirement({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        acquaintedRequirement(params)
              .then(res => {
                  commit("SET_STAFF", res.data.result.data.staffs);
                  resolve(res.data.result.data.staffs);                  
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
    }, 
    getStaffRequirement({ commit }, id) {
      return new Promise((resolve, reject) => {
        getStaffRequirement(id)
              .then(res => {
                  commit("SET_STAFF_REQUIREMENT", res.data.result.data.client_requirements);
                  resolve(res);


                  // commit("REFRESH");
                  
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
    }, 
    getStaffContact({ commit }, staff_id) {
      return new Promise((resolve, reject) => {
          getStaffContact(staff_id)
              .then(res => {
                  if(res.data.result.data.contact.id)
                  {
                      commit('SET_STAFF_CONTACT', res.data.result.data.contact);
                  }
                  else{                        
                      commit('EMPTY_STAFF_CONTACT');
                  }                    
                  resolve(res);
              })
              .catch(err => {
                  commit('EMPTY_STAFF_CONTACT');
                  reject(err);
              });
      });
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    updatePaginationAttendance({ commit }, pagination) {
        commit('UPDATE_PAGINATION_ATTENDANCE', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
