<template>
    <div  v-loading="condition.length <= 0" class="condition">
        <div class="performers__top" >
            <h5 class="condition__top" :class="mode ? 'performance__topday' : 'performance__top'">{{ title }}</h5>
        </div>
        <el-row>
            <el-col :span="24">
                <div class="condition__box">
                    <ul class="condition__ul">
                        <li v-for="(con, index) in condition" :key="index" class="condition__li" >
                          <div @dblclick="showStaffList(branchid, con.title)">
                            <a class="condition__info" :class="mode ? 'condition__infoday' : 'condition__infonight'" >
                              <div class="condition__intotop">
                                <h4 class="condition__title">{{ $t('message.' + con.title) }}</h4>
                              </div>
                              <div class="condition__intobottom">
                                <span class="condition__quantity">{{ con.count }}</span>
                                <span class="condition__yes">
                                  <span class="condition__iconred" v-if="tyu">
                                      <svg data-v-b907efca="" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="
                                          feather
                                          feather-trending-up
                                      "><polyline data-v-b907efca="" points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline data-v-b907efca="" points="17 6 23 6 23 12"></polyline>
                                    </svg>
                                  </span>
                                  <span class="condition__icongreen"  v-else :style="con.style" style="width:35px;height:35px;font-size:20px">
                                      <!-- <svg data-v-b907efca="" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="
                                          feather
                                          feather-trending-up
                                      "><polyline data-v-b907efca="" points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline data-v-b907efca="" points="17 6 23 6 23 12"></polyline>
                                    </svg> -->
                                    <i :class="con.icon" ></i>
                                  </span>
                                  <!-- <span class="condition__per" :class="tyu ? 'condition__perred' : 'condition__pergreen'">
                                    {{ con.procent }}%
                                  </span> -->
                                </span>
                              </div>
                            </a>
                          </div>
                        </li>
                    </ul>
                </div>
            </el-col>
        </el-row>

        <el-drawer
        title="I'm outer Drawer"
        :visible.sync="showUseerModal"
        size="30%"
        :with-header="false"
        ref="show-users"
        :class="mode ? 'drawer-day' : 'drawer-night'"
        @opened="drawerOpened('show-users')"
      >
        <div>
          <show-users-home
            :staff="selected"
            @closeUserModal="closeUserModal"
            :child="child"
            ref="show-users"
          ></show-users-home>
        </div>
      </el-drawer>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import showUsersHome from "./show-users-home.vue";
export default {
    name: 'staff-condition',
    props: ['condition', 'title', 'branchid'],
    components: {
      showUsersHome
    },
    data: () => ({
      tyu: false,
      child: {},
      selected: null,
      showUseerModal: false,

    }),
    computed: {
        ...mapGetters({
            mode: "MODE"
        })
    },
    methods:{
      getStaffList (e) {
          console.log(e)
        },
        showStaffList(branch_id, method) {
          if (['in_work','not_come_work','late_to_work','accepted_take_assent','sick_leave', 'on_vacation'].includes(method)) {
            this.showUseerModal = true;
            this.child.branch_id = branch_id;
            this.child.method = method;
          }
         
        },
      closeUserModal(val) {
        this.showUseerModal = val;
      },
      drawerOpened(ref) {
          if (this.$refs[ref]) {
            if (_.isFunction(this.$refs[ref].opened)) {
              this.$refs[ref].opened(this.child);
            }
          }
        },
    }
}
</script>

<style scoped lang="scss">
    .drawer-night{
      background: transparent !important;
      .el-drawer{
        background: transparent;
      }
    }
    .condition__li {
      width: 100%;
    }
    .condition__info {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
        box-shadow: none;
        border-radius: 12px;
        cursor: pointer;
        height: 100px;
        margin-top: 10px;
        padding: 8px 10px;
    }
    .condition__ul li{
      margin: 0 10px;
    }
    .condition__ul li:last-child {
      margin-right: 0px;
    }
    .condition__ul li:first-child {
      margin-left: 0;
    }
    .condition__infoday {
      background: #ffffffdb;
      /* border: 1px solid #a2aab6; */
      box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    }
    .condition__infonight {
      border: 1px dashed #323248;
      background-color: #373747;
    }
    .condition__infoday:hover {
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }
    .condition__ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .condition h4 {
      margin: 0;
    }
    .condition__box {
      margin-top: 10px;
      padding: 0 0px;
    }
    .condition {
      border-radius: 20px;
      min-height: 150px;
    }
    
    
</style>
